import Web3 from "web3";
import { arbitrum_url, boba_url } from "./constants";
 
window.ethereum.request({ method: "eth_requestAccounts" });
 
const web3 = new Web3(window.ethereum);
export const web3Arbitrum = new Web3(arbitrum_url);
export const web3Boba = new Web3(boba_url);
 
export default web3;
